import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnyxAddressType, OnyxPaginated } from '../components';
import { OnyxAddress } from '../components/address-input/interfaces';

@Injectable({
  providedIn: 'root',
})
export abstract class OnyxAddressService {
  public abstract geocode(
    types: OnyxAddressType[],
    query: string,
    limit?: number,
  ): Observable<OnyxPaginated<OnyxAddress>>;

  public abstract getLastSearches(
    types: OnyxAddressType[],
  ): Observable<OnyxAddress[]>;

  public abstract addToLastSearches(_address: OnyxAddress): Observable<void>;
}
